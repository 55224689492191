

.inicio-container {
    display: flex;
    background-color: rgba(233, 138, 13, 0.068);
    box-shadow: 5px;
  }
  
  .inicio-container h1 {
    text-align: center;
    font-family: "airstrike";
    color:  #e98f22;
    font-size: 180%;
    margin-bottom: 20px;
  }
  
  .inicio-container h2 {
    text-align: center;
    font-family: "airstrike";
    color:  #e98f22;
    font-size: 180%;
    margin-bottom: 20px;
  }
  
  .inicio-container h3 {
    text-align: center;
    font-family: "airstrike";
    color:  #e98f22;
    font-size: 180%;
    margin-bottom: 20px;
  }
  
  .inicio-container p {
    font-family:"oswald";
    color:#ffffff;
    font-size: 120%;
    text-align: center;
  }

  #logo{
    max-width: 100%; /* Asegura que la imagen no supere el ancho del contenedor */
    width: 100vw;
  }
  
  #imageini{
    width: 100%;
    background-image: url("../images/Senor-2.png");
    background-size: 25vh;
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0);
    background-position: left bottom;
  }
 
  #info{
    max-width: 70%;
  }

  #imageAcerca {
    width: 25vw;
    height: auto;
    background-image: url("../images/electrician-1080573_1280.jpg");
    background-size: 70vw;
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0);
    background-position: center;
    margin: 10px;
  }

  .servicios-container {
    background-color: rgba(233, 138, 13, 0.068);
    box-shadow: 5px;
    padding: 0px;
    margin: 0px;
  }
  
  .servicios-container h4 {
    text-align: center;
    font-family: "airstrike";
    color:  #e98f22;
    font-size: 180%;
    margin-bottom: 20px;
  }
  
  .servicios-container ul {
    font-family:"oswald";
    color:#ffffff;
    font-size: 120%;
    text-align: left;
  }

  .contacto-container {
    background-color: rgba(233, 138, 13, 0.068);
    box-shadow: 5px;
    padding: 10px;
    margin: 0px;
    font-family:"oswald";
    color:#ffffff;
  }

  .contacto-container a {
    font-family:"oswald";
    color:#ffffff;
    font-size: 120%;
    text-align: left;
  }

  .contacto-container img{
    width: 90vw;
  }
  #instagram{
    border:none; 
    overflow:hidden;  
    width:100%; 
    height:100%;
  }
  iframe{
    margin-top: 10%;
    padding: 2%;
    background-color: rgba(233, 138, 13, 0.068);
    box-shadow: 5px;
    width:92vw; 
    height:100vh;
  } 
