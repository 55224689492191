/* Estilos generales */
@font-face {
  font-family: "airstrike";
  src: url("airstrike-webfont.woff2");
}
@font-face {
  font-family: "oswald";
  src: url("oswald-variablefont_wght-webfont.woff2");
}
#root {
  display: flex;
  flex-direction: column;
  --negro : rgb(0, 0, 0);
  --negro-medio : rgba(0, 0, 0, 0.9);
  --claro : rgb(255, 255, 255);
  --amarillo :  #e99022;
  --traslucido: rgba(255, 255, 255, 0);
}
#trampa {
  color: black;
  background-color: black;
  font-size: 0px;
}
button{
  background-color: var(--traslucido);
  padding: 10px;
  border: 0px;
}
button :hover{
  background-color: var(--amarillo);
}
#contenido{
  padding: 2%;
  min-height: 80vh;
  background-image: linear-gradient( rgba(0, 0, 0, 0.7), rgb(0, 0, 0)), url("../images/tool-2766835_1280.jpg");
}
body {
  background-color: var(--negro);
  font-family: "oswald";
  padding: 0px;
  margin: 0px;
}

/* Estilos del navbar */
header {
  background-color: var(--negro-medio);
  color: var(--claro);
  padding: 10px;
}

nav {
  background-color: #1d1d1d;
  padding: 10px;
}
nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  display: inline-block;
}

nav ul li a {
  font-family: "airstrike";
  font-size: 120%;
  color: var(--claro);
  text-decoration: none;
}

/* Estilos del banner */
#banner{
  max-height: 300px;
  display: flex;
  background-image: url("../images/Banner_1200px_300px.png");
  background-repeat: no-repeat;
  background-color: black;
  font-family: "oswald";
  color: var(--claro);
  align-items: flex-end;
}
#texto{
  padding-left: 5%;
  font-size: 70%;
  flex: 1;
  text-align: left;
  align-items: start;
}
#banner a{
  color: var(--claro);
}
#banner img{
  float: right;
  max-width: 27%; /* Asegura que la imagen no supere el ancho del contenedor */
  height: 100%;
}

/* Estilos del pie de página */
footer {
  font-family:"oswald";
  background-color: var(--negro-medio);
  color: var(--amarillo);
  padding: 1%;
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  max-height: 15%;
}
footer a{
  color: var(--amarillo);
}
#whatsapp{
  font-family:"oswald";
  background-color: #19b236;
  color:var(--claro);
  padding: 10px;
  border-radius: 10px;
  border: 2px;
}
#whatsapp:hover{
  background-color: #19b23575;
  border: 15px;
}
